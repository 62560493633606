import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {PaginatorModule} from "primeng/paginator";
import {ReservationService} from "../../../../../../../core/services/reservations/reservation.service";
import {forkJoin, timestamp} from "rxjs";
import {Service} from "../../../../../../../models/Service";
import {Vehicle} from "../../../../../../../models/Vehicle";
import {FormErrorDirective} from "../../../../../../../shared/directives/forms/form-error.directive";
import {FormInputDirective} from "../../../../../../../shared/directives/forms/form-input.directive";
import {FormLabelDirective} from "../../../../../../../shared/directives/forms/form-label.directive";
import {JsonPipe, NgForOf, NgIf} from "@angular/common";
import {LoaderComponent} from "../../../../../../../shared/components/loader/loader.component";
import {Step2Quadrants} from "../../../../../../../models/StationForm/Step2Quadrants";
import {StationFormService} from "../../../../../../../core/services/stations/station-form.service";
import {FuelService} from "../../../../../../../core/services/fuels/fuel.service";
import {Fuel} from "../../../../../../../models/Fuel";
import {VehiclesService} from "../../../../../../../core/services/vehicles/vehicles.service";
import {ServicesService} from "../../../../../../../core/services/services/services.service";
import {NextButtonDirective} from "../../../../../../../shared/directives/buttons/next.directive";
import {FrontBackDirective} from "../../../../../../../shared/directives/buttons/front.back.directive";
import {OutlineButtonDirective} from "../../../../../../../shared/directives/outline/outline-button.directive";
import {Quadrant} from "../../../../../../../models/Quadrant";
import {ToastrService} from "ngx-toastr";
import {OverlayPanelModule} from "primeng/overlaypanel";

@Component({
  selector: 'app-step2-station',
  standalone: true,
  imports: [
    PaginatorModule,
    ReactiveFormsModule,
    FormErrorDirective,
    FormInputDirective,
    FormLabelDirective,
    NgIf,
    NgForOf,
    LoaderComponent,
    NextButtonDirective,
    FrontBackDirective,
    OutlineButtonDirective,
    OverlayPanelModule,
    JsonPipe
  ],
  templateUrl: './step2-station.component.html',
  styleUrl: './step2-station.component.css'
})
export class Step2StationComponent {
  form: FormGroup;
  loading: boolean = false;

  services: Service[] = [];
  servicesToAdd: Service[] = [];
  vehicles: Vehicle[] = [];
  vehiclesToAdd: Vehicle[] = [];
  fuels: Fuel[] = [];
  fuelsToAdd: Fuel[] = [];

  @Input() editStation:boolean=false;
  @Output() onAddQuadrants: EventEmitter<Step2Quadrants[]> = new EventEmitter();

  @Input() quadrants:Quadrant[]|undefined = [];

  allFuelSelected:boolean=false;

  toCreateQuadrants: Step2Quadrants[] = [];

  constructor(private fb: FormBuilder,
              private reservationService: ReservationService,
              public stationFormService: StationFormService,
              private fuelService: FuelService,
              private vehiclesService: VehiclesService,
              private serviceService: ServicesService,
              private toastr:ToastrService) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      services_check: [],
      vehicles_check: [],
      fuels_check: [],
      professional: false,
      link_type: false,
      link: [''],
      services: this.fb.array([], [Validators.required, Validators.minLength(1)]),
      vehicles: this.fb.array([], [Validators.required, Validators.minLength(1)]),
      fuels: this.fb.array([], [Validators.required, Validators.minLength(1)]),
    });
    this.loadElements();
    this.refreshStep();
  }

  refreshStep(){
    this.stationFormService.stationForm$.subscribe(station => {
      if (station.step2) {
        this.toCreateQuadrants = station.step2.quadrants;
      }
    })
  }

  reset(){
    this.toCreateQuadrants = [];
  }

  loadElements(){
    this.loading = true;
    forkJoin({
      services: this.serviceService.list(),
      vehicles: this.vehiclesService.list(),
      fuels: this.fuelService.list()
    }).subscribe({
      next: (results) => {
        this.services = results.services.data ?? [];
        this.vehicles = results.vehicles.data ?? [];
        this.fuels = results.fuels.data ?? [];

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        // Manejar errores aquí
        console.error('Error al llamar a los observables:', error);
      }
    });
  }

  onCheckboxChange(e: any, control: string, obj: any) {
    const selectedItems: FormArray = this.form.get(control) as FormArray;

    if (e.target.checked) {
      // Agregar el objeto al FormArray
      selectedItems.push(this.fb.group(obj));
    } else {
      // Buscar el índice del objeto en el FormArray
      const index = selectedItems.controls.findIndex(x => x.value.id === obj.id);

      if (index !== -1) {
        selectedItems.removeAt(index);
      }
    }
  }

  removeQuadrant(pos: number){
   this.toCreateQuadrants.splice(pos,1);
    this.stationFormService.setStep2({
      quadrants: this.toCreateQuadrants
    })
  }

  isAnyQuadrantEmpty(){
    return this.toCreateQuadrants.some(q=>q.services.length<1 || q.vehicles.length<1 || q.fuels.length<1)
  }

  allCombustibles() {
    const selectedItems: FormArray = this.form.get('fuels') as FormArray;
    selectedItems.clear();

    const fuelsCheckControl: FormControl = this.form.get('fuels_check') as FormControl;
    fuelsCheckControl.setValue(true);
    this.fuels.forEach(fuel => {
      this.onCheckboxChange({target: {value: fuel.id, checked: true}}, 'fuels', fuel);
    })
  }

  allVehicles() {
    const selectedItems: FormArray = this.form.get('vehicles') as FormArray;
    selectedItems.clear();

    const fuelsCheckControl: FormControl = this.form.get('vehicles_check') as FormControl;
    fuelsCheckControl.setValue(true);
    this.vehicles.forEach(vehicle => {
      this.onCheckboxChange({target: {value: vehicle.id, checked: true}}, 'vehicles', vehicle);
    })
  }

  allServices() {
    const selectedItems: FormArray = this.form.get('services') as FormArray;
    selectedItems.clear();

    const fuelsCheckControl: FormControl = this.form.get('services_check') as FormControl;
    fuelsCheckControl.setValue(true);
    this.services.forEach(service => {
      this.onCheckboxChange({target: {value: service.id, checked: true}}, 'services', service);
    })
  }

  isServiceInArray(service: Service, services:Service[]): boolean {
    // Busca si el fuel existe en el arreglo de fuels del quadrant
    return services.some(s => s.id === service.id);
  }

  addService(quadrantPos:any,service: Service) {
    // Verifica si el servicio ya está en el array servicesToAdd
    const index = this.toCreateQuadrants[quadrantPos].services.findIndex(s => s.id === service.id);

    if (index !== -1) {
      // Si el servicio ya está, lo elimina del array
      if(this.toCreateQuadrants[quadrantPos].services.length>1){
        this.toCreateQuadrants[quadrantPos].services.splice(index,1);
        this.stationFormService.setStep2({
          quadrants: this.toCreateQuadrants
        })
      }else{
        this.toastr.info('Debe haber como mínimo un servicio')
      }
    } else {
      this.toCreateQuadrants[quadrantPos].services.push(service);
      this.stationFormService.setStep2({
        quadrants: this.toCreateQuadrants
      })
    }
  }

  isVehicleInArray(vehicle: Vehicle, vehicles:Vehicle[]): boolean {
    // Busca si el fuel existe en el arreglo de fuels del quadrant
    return vehicles.some(v => v.id === vehicle.id);
  }
  addVehicle(quadrantPos:any,vehicle: Vehicle) {
    // Verifica si el vehicle ya está en el array vehiclesToAdd
    const index = this.toCreateQuadrants[quadrantPos].vehicles.findIndex(v => v.id === vehicle.id);

    if (index !== -1) {
      // Si el vehicle ya está, lo elimina del array
      if(this.toCreateQuadrants[quadrantPos].vehicles.length>1){
        this.toCreateQuadrants[quadrantPos].vehicles.splice(index,1);
        this.stationFormService.setStep2({
          quadrants: this.toCreateQuadrants
        })
      } else {
        this.toastr.info('Debe haber como mínimo un vehículo')
      }
    } else {
      // Si el vehicle no está, lo añade al array
      this.toCreateQuadrants[quadrantPos].vehicles.push(vehicle);
      this.stationFormService.setStep2({
        quadrants: this.toCreateQuadrants
      })
    }
  }

  isFuelInArray(fuel: Fuel, fuels:Fuel[]): boolean {
    // Busca si el fuel existe en el arreglo de fuels del quadrant
    return fuels.some(f => f.id === fuel.id);
  }

  addFuel(quadrantPos:any,fuel: Fuel) {
    // Verifica si el combustible ya está en el array
    const index = this.toCreateQuadrants[quadrantPos].fuels.findIndex(f => f.id === fuel.id);

    if (index !== -1) {
      // Si el combustible ya está, lo elimina del array
      if(this.toCreateQuadrants[quadrantPos].fuels.length>1){
        this.toCreateQuadrants[quadrantPos].fuels.splice(index,1);
        this.stationFormService.setStep2({
          quadrants: this.toCreateQuadrants
        })
      } else {
        this.toastr.info('Debe haber como mínimo un combustible')
      }
    } else {
      // Si el combustible no está, lo añade al array
      this.toCreateQuadrants[quadrantPos].fuels.push(fuel);
      this.stationFormService.setStep2({
        quadrants: this.toCreateQuadrants
      })
    }
  }

  checkQuadrantHasService(serviceId: number): boolean {
    if (this.quadrants && this.editStation) {
      let recorrerCuadrants = [...this.quadrants, ...this.toCreateQuadrants];
      return recorrerCuadrants.some(quadrant => {
        // Verificar si el cuadrante tiene el servicio con el ID especificado
        // @ts-ignore
        const serviceExists = quadrant.services.some((service: { id: number }) => service.id === serviceId);
        // console.log(serviceExists && quadrant.professional == this.form.value.professional);
        if (serviceExists && quadrant.professional == this.form.value.professional) {
          // Verificar si algún vehículo del formulario coincide con los vehículos del cuadrante
          const formVehicles = this.form.value.vehicles || []; // Vehículos del formulario
          return formVehicles.some((formVehicle: { id: number }) =>
            // @ts-ignore
            quadrant.vehicles.some((quadrantVehicle: { id: number, pivot: any }) => {
              if (quadrantVehicle.pivot) {
                return quadrantVehicle.id === formVehicle.id && quadrantVehicle.pivot.visible;
              } else {
                return quadrantVehicle.id === formVehicle.id;
              }
            })
          );
        }

        return false; // Si no existe el servicio, continuar con el siguiente cuadrante
      });
    } else {
      return this.toCreateQuadrants.some(quadrant => {
        // Verificar si el cuadrante tiene el servicio con el ID especificado
        // @ts-ignore
        const serviceExists = quadrant.services.some((service: { id: number }) => service.id === serviceId);

        if (serviceExists && quadrant.professional == this.form.value.professional) {
          // Verificar si algún vehículo del formulario coincide con los vehículos del cuadrante
          const formVehicles = this.form.value.vehicles || []; // Vehículos del formulario
          return formVehicles.some((formVehicle: { id: number }) =>
            // @ts-ignore
            quadrant.vehicles.some((quadrantVehicle: { id: number }) => quadrantVehicle.id === formVehicle.id)
          );
        }

        return false; // Si no existe el servicio, continuar con el siguiente cuadrante
      });
    }
  }

  quadrantAlreadyExists(){

    return this.form.value.services.some((service: any) => {
      return this.checkQuadrantHasService(service.id);
       // Si no hay coincidencia, continuar.
    });

  }

  generateQuadrant(){
    if (this.form.valid) {
      if(this.editStation){
        if(this.quadrants){
          // console.log(this.form.value);
          // console.log(this.quadrants[0]);
          let existingQuadrant = this.quadrants.some(q =>
            q.name === this.form.get('name')?.value);

          let alreadyExists = this.quadrantAlreadyExists();

          if(existingQuadrant){
            this.toastr.error('Ya existe ese cuadrante');
          } else if (alreadyExists) {
            this.toastr.error('Ya tienes un cuadrante con este servicio y vehículo');
          } else {

            // console.log(this.form.value);
            this.toCreateQuadrants.push({
              ...this.form.value,
              sections: [],
              id: Math.floor(Date.now() / 1000)
            });
            this.form.reset();

            // Clear the FormArray items manually
            (this.form.get('services') as FormArray).clear();
            (this.form.get('vehicles') as FormArray).clear();
            (this.form.get('fuels') as FormArray).clear();
            this.form.patchValue({
              professional: false,
              link_type: false
            })
          }
        }
      } else {
        let alreadyExists = this.quadrantAlreadyExists();

        if (alreadyExists) {
          this.toastr.error('Ya tienes un cuadrante con este servicio y vehículo');
        } else {
          // console.log(this.form.value);
          this.toCreateQuadrants.push({
            ...this.form.value,
            sections: [],
            id: Math.floor(Date.now() / 1000)
          });
          this.form.reset();

          // Clear the FormArray items manually
          (this.form.get('services') as FormArray).clear();
          (this.form.get('vehicles') as FormArray).clear();
          (this.form.get('fuels') as FormArray).clear();
          this.form.patchValue({
            professional: false,
            link_type: false
          })
        }

      }

    } else {
      this.form.markAllAsTouched();
    }
  }

  next(){
    if (this.editStation) {
      this.onAddQuadrants.emit(this.toCreateQuadrants);
      this.toCreateQuadrants = [];
      this.form.reset();

      // Clear the FormArray items manually
      (this.form.get('services') as FormArray).clear();
      (this.form.get('vehicles') as FormArray).clear();
      (this.form.get('fuels') as FormArray).clear();
      this.form.patchValue({
        professional: false,
        link_type: false
      })
    } else {
      this.stationFormService.setStep2({
        quadrants: this.toCreateQuadrants
      })
      this.stationFormService.changeToStep(3);
    }

  }
}
