<div class="grid grid-cols-1 xl:grid-cols-3 gap-x-6 gap-y-8">
<app-generic-table class="col-span-1 order-2 xl:order-1 xl:col-span-2"
                   #genericTableComponent
  [loading]="loadingList"
  [tableHeaders]="tableHeaders"
  (changeSorts)="onChangeSorts($event)"
  [items]="users"
  [tableActions]="tableActions"
  (clickAction)="onClickAction($event)"
  [showDirectAction]="true">


  <app-table-header-wrapper GTHeader >
    <!--<app-user-table-filters [perPageOptions]="[50,100,150,200]" (onChangePerPage)="perPage = $event; loadReservations()" [perPage]="perPage" [tableHeaders]="tableHeaders" [filters]="filters" (onChangeFilters)="onChangeFilters($event)"></app-user-table-filters>-->
    <app-table-filters
      [filtersList]="tableFilters"
      [loading]="loadingFilters"
      [perPageOptions]="[50,100,150,200]"
      [perPage]="perPage"
      [filters]="filters"
      (onChangeFilters)="onChangeFilters($event)"
      [tableHeaders]="tableHeaders"
      [bulkActions]="bulkActions"
      (bulkActionClick)="bulkActionClick($event)"
    >


    </app-table-filters>
  </app-table-header-wrapper>



</app-generic-table>
  <app-card-shadow class="order-1 xl:order-2">
    <h1 class="text-primary text-xl my-6">Nuevo usuario</h1>
    <form [formGroup]="userForm">
      <div class="flex flex-col w-full items-center gap-y-6">
        <div class="flex flex-col w-full max-w-96">
          <input formControlName="name" type="text" appAuthFormInput placeholder="Nombre">
        </div>
        <div class="flex flex-col w-full max-w-96">
          <input formControlName="email" type="text" appAuthFormInput placeholder="Email">
        </div>
        <div class="flex flex-col w-full max-w-96" *ngIf="selectedRole?.name === 'professional'">
          <input formControlName="phone" type="text" appAuthFormInput placeholder="Teléfono">
        </div>
        <div class="flex flex-col w-full max-w-96">
          <input formControlName="password" type="password" appAuthFormInput placeholder="Contraseña">

        </div>
        <div class="flex w-full max-w-96 justify-center">
          <select appFormSelectSquare [(ngModel)]="selectedRole" [ngModelOptions]="{standalone: true}">
            <option disabled [ngValue]="undefined">Selecciona un rol</option>
            <option *ngFor="let role of roles" [ngValue]="role"> {{role.name}} </option>
          </select>
        </div>

        <div *ngIf="selectedRole?.name === 'director' || selectedRole?.name === 'professional'  || selectedRole?.name === 'operador'">
          <p-multiSelect
            (click)="$event.stopPropagation()"
                         [options]="stations"
                         optionValue="id"
                          [group]="true"
                         formControlName="stations"
                         optionLabel="name"
                         display="chip"
            placeholder="Estaciones">

            <ng-template let-group pTemplate="group" >
              <div class="flex align-items-center" *ngIf="group.img">
                <img
                  [src]="group.img"
                  [class]="'mr-2'"
                  style="height: 25px; width: auto" />
                <span>{{ group.label }}</span>
              </div>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <div  class="flex align-items-center gap-2" *ngIf="item.logo">
                <img
                  [src]="item.logo.original_url"
                  style="width: 20px; height: auto" />
                <div>{{ item.name }}</div>
              </div>
              <div  class="flex align-items-center gap-2" *ngIf="!item.logo">
                <div>{{ item.name }}</div>
              </div>
            </ng-template>

          </p-multiSelect>
        </div>


        <div class="w-full max-w-96">
          <button (click)="createUser()" appNextButton class="w-full">
            <p class="text-lg">CREAR</p>
             <app-loader size="extra-small" *ngIf="loadingCreate"></app-loader>
          </button>
        </div>

      </div>
    </form>
  </app-card-shadow>
</div>


<ng-container *ngIf="displayEditDialog">
  <div (click)="displayEditDialog = false" class="fixed inset-0 bg-black bg-opacity-35 animate-fade-in cursor-pointer z-40"></div>
  <div (click)="displayEditDialog = false" class="fixed inset-0 flex items-center justify-center px-8 h-full z-50">
    <div (click)="$event.stopPropagation()" class="bg-white p-8 rounded-lg shadow-lg w-full max-w-2xl animate-fade-in-up">


      <form class="p-2" [formGroup]="editUserForm">
        <div class="flex flex-col w-full items-center gap-y-6">
          <div class="flex flex-col w-full max-w-96">
            <input formControlName="name" type="text" appAuthFormInput placeholder="Nombre">
          </div>
          <div class="flex flex-col w-full max-w-96">
            <input formControlName="email" type="text" appAuthFormInput placeholder="Email">
          </div>
          <div class="flex flex-col w-full max-w-96" *ngIf="selectedEditUserRole?.name === 'professional'">
            <input formControlName="phone" type="text" appAuthFormInput placeholder="Teléfono">
          </div>
          <div class="flex flex-col w-full max-w-96">
            <input formControlName="password" type="password" appAuthFormInput placeholder="Nueva Contraseña">

          </div>
          <div class="flex w-full max-w-96 justify-center">
            <select appFormSelectSquare [(ngModel)]="selectedEditUserRole" [ngModelOptions]="{standalone: true}">
              <option disabled [ngValue]="undefined">Selecciona un rol</option>
              <option *ngFor="let role of roles" [ngValue]="role" [selected]="selectedEditUserRole?.id === role.id"> {{role.name}} </option>
            </select>
          </div>



          <div *ngIf="selectedEditUserRole?.name === 'director' || selectedEditUserRole?.name === 'professional' || selectedEditUserRole?.name === 'operador'">
            <p-multiSelect
              (click)="$event.stopPropagation()"
              [options]="stations"
              [group]="true"
              optionValue="id"
              formControlName="stations"
              optionLabel="name"
              display="chip"
              placeholder="Estaciones">
              <ng-template let-group pTemplate="group" >
                <div class="flex align-items-center" *ngIf="group.img">
                  <img
                    [src]="group.img"
                    [class]="'mr-2'"
                    style="height: 25px; width: auto" />
                  <span>{{ group.label }}</span>
                </div>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div  class="flex align-items-center gap-2" *ngIf="item.logo">
                  <img
                    [src]="item.logo.original_url"
                    style="width: 20px; height: auto" />
                  <div>{{ item.name }}</div>
                </div>
                <div  class="flex align-items-center gap-2" *ngIf="!item.logo">
                  <div>{{ item.name }}</div>
                </div>
              </ng-template>
            </p-multiSelect>
          </div>


          <div class="w-full max-w-96">
            <button (click)="editUser()" appNextButton class="w-full">
              <p class="text-lg">EDITAR</p>
              <app-loader size="extra-small" *ngIf="loadingEdit"></app-loader>
            </button>
          </div>

        </div>
      </form>

    </div>
  </div>
</ng-container>

<input
  #fileInput
  type="file"
  accept=".csv, .xls, .xlsx"
  (change)="onFileSelected($event)"
  style="display: none;"
/>
<!--

<p-dialog [style]="{width:'50rem'}" [(visible)]="displayEditDialog" [modal]="true" [closable]="true" header="Editar Usuario">
  <form class="p-2" [formGroup]="editUserForm">
    <div class="flex flex-col w-full items-center gap-y-6">
      <div class="flex flex-col w-full max-w-96">
        <input formControlName="name" type="text" appAuthFormInput placeholder="Nombre">
      </div>
      <div class="flex flex-col w-full max-w-96">
        <input formControlName="email" type="text" appAuthFormInput placeholder="Email">
      </div>
      <div class="flex flex-col w-full max-w-96">
        <input formControlName="password" type="password" appAuthFormInput placeholder="Nueva Contraseña">

      </div>
      <div class="flex w-full max-w-96 justify-center">
        <select appFormSelectSquare [(ngModel)]="selectedEditUserRole" [ngModelOptions]="{standalone: true}">
          <option disabled [ngValue]="undefined">Selecciona un rol</option>
          <option *ngFor="let role of roles" [ngValue]="role" [selected]="selectedEditUserRole?.id === role.id"> {{role.name}} </option>
        </select>
      </div>

      <div *ngIf="selectedEditUserRole?.name === 'director'">
        <p-multiSelect
          (click)="$event.stopPropagation()"
          [options]="stations"
          optionValue="id"
          formControlName="stations"
          optionLabel="name"
          display="chip"
          placeholder="Estaciones"/>
      </div>


      <div class="w-full max-w-96">
        <button (click)="editUser()" appNextButton class="w-full">
          <p class="text-lg">EDITAR</p>
          <app-loader size="extra-small" *ngIf="loadingEdit"></app-loader>
        </button>
      </div>

    </div>
  </form>
</p-dialog>
-->
