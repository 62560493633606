import { Injectable } from '@angular/core';
import {map, Observable} from "rxjs";
import {Response} from "../../../models/Response";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Station} from "../../../models/Station";
import {State} from "../../../models/State";
import {StationForm} from "../../../models/StationForm/StationForm";
import {Quadrant} from "../../../models/Quadrant";
import {QuadrantInfo} from "../../../models/QuadrantInfo";
import {QuadrantLine} from "../../../models/QuadrantLine";
import {Step2Quadrants} from "../../../models/StationForm/Step2Quadrants";
import {SortColumn} from "../../../models/Table/SortColumn";
import {ActiveFilter} from "../../../models/Table/ActiveFilter";
import {completeHttpFiltersAndSorts} from "../../../const/functions";
import {AvailableHoursInfo} from "../../../models/AvailableHours";



@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(private _http: HttpClient) { }

  statisticsReservations(sorts: SortColumn[] = [], filters: ActiveFilter[] = [], perPage = 50): Observable<Response<any>>{
    let params = completeHttpFiltersAndSorts(sorts, filters);
    return this._http.get<Response<any[]>>(`${environment.API_URL}/statistics/reservations`, {params});
  }

  statisticsOfferedReservations(sorts: SortColumn[] = [], filters: ActiveFilter[] = [], perPage = 50): Observable<Response<any>>{
    let params = completeHttpFiltersAndSorts(sorts, filters);
    return this._http.get<Response<any[]>>(`${environment.API_URL}/statistics/reservationsOffered`, {params});
  }


}
