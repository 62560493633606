import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  CreateQuadrantHour,
  exportHoursToCsv,
  exportHoursToJson, exportHoursToTxt,
  generateHourList, importHourFromJson, importHoursFromCsv, importHoursFromTxt
} from "../../../models/StationForm/CreateQuadrantHour";
import {QuadrantSection} from "../../../models/QuadrantSection";
import {NgForOf, NgIf} from "@angular/common";
import {ExportComponent} from "../export/export.component";
import {ImportComponent} from "../import/import.component";
import {ToastrService} from "ngx-toastr";
import {OutlineButtonDirective} from "../../directives/outline/outline-button.directive";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {FormErrorDirective} from "../../directives/forms/form-error.directive";
import {FormInputDirective} from "../../directives/forms/form-input.directive";
import {QuadrantLine} from "../../../models/QuadrantLine";
import {NextButtonDirective} from "../../directives/buttons/next.directive";
import {AvailableHour} from "../../../models/AvailableHours";

@Component({
  selector: 'app-hours-form',
  standalone: true,
  imports: [
    NgForOf,
    ExportComponent,
    ImportComponent,
    NgIf,
    OutlineButtonDirective,
    FormErrorDirective,
    FormInputDirective,
    FormsModule,
    ReactiveFormsModule,
    NextButtonDirective
  ],
  templateUrl: './hours-form.component.html',
  styleUrl: './hours-form.component.css'
})
export class HoursFormComponent implements OnChanges {
  hours: CreateQuadrantHour[] = [];

  @Input() showImportExport: boolean = true;

  @Input() onEditMode: boolean = false;

  @Input() manualSelectDays: boolean = false;

  weekDays: any[] = [
    { day: 'L', name: 'Lunes', key: 1 },
    { day: 'M', name: 'Martes', key: 2 },
    { day: 'X', name: 'Miércoles', key: 3 },
    { day: 'J', name: 'Jueves', key: 4 },
    { day: 'V', name: 'Viernes', key: 5 },
    { day: 'S', name: 'Sábado', key: 6 },
    { day: 'D', name: 'Domingo', key: 0 },
  ];

  @Output() onSaveWorkDays = new EventEmitter<void>();
  @Output() onSaveSaturdays = new EventEmitter<void>();
  @Output() onManualSelectDays = new EventEmitter<boolean>();

  @Output() onCancel = new EventEmitter<void>();

  @Input() manualSelectDay: boolean = false;
  @Output() onManualSelectDay = new EventEmitter<boolean>();

  @Output() onFormSubmit = new EventEmitter<any>();

  @Output() onUpdateDates = new EventEmitter<string[]>();

  ofertadas = 0;


  sectionForm!: FormGroup;

  @Input() selectedQuadrantLine: QuadrantLine|QuadrantSection|undefined;

  constructor(private toastr: ToastrService,private fb: FormBuilder,private cdr: ChangeDetectorRef) {
    this.sectionForm = this.fb.group({
      id: new FormControl(null, []),
      name: new FormControl('', [Validators.required]),
      info: this.fb.array([], [Validators.required, Validators.minLength(1)]), // Inicializa todos los días como no seleccionados
      color: new FormControl('#000000', Validators.required)
    });

    this.generateHourList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedQuadrantLine']) {
      if (this.selectedQuadrantLine) {
        this.loadData(this.selectedQuadrantLine);
        this.calculateSelectedOnEdit();
      } else {
        this.generateHourList();
        // this.cancelManualSelectedDays();
        this.sectionForm.reset();
        this.ofertadas = 0;
      }
    }
  }

  calculateSelectedOnEdit(){
   this.ofertadas = 0;
    this.selectedQuadrantLine?.hours.forEach(hour => {
      // @ts-ignore
      this.ofertadas += hour.pivot.capacity;
    })
  }

  calculateHours(){
    this.ofertadas = 0;
    this.hours.forEach(hour => {
      this.ofertadas += hour.capacity;
    })
  }

  /*cancelManualSelectedDays(){
    this.manualSelectDays = false;
    this.onManualSelectDays.emit(false);
  }*/

  loadData(selectedQuadrantLine: QuadrantLine | QuadrantSection) {
    this.sectionForm.patchValue({
      id: "id" in selectedQuadrantLine ? selectedQuadrantLine.id : null,
      name: selectedQuadrantLine.name,
      color: selectedQuadrantLine.color,
    });

    const selectedDays = this.sectionForm.get('info') as FormArray;
    selectedDays.clear(); // Limpia el FormArray existente

    // Añade los valores del FormArray
    if("info" in selectedQuadrantLine){
      selectedQuadrantLine.info.forEach(day => {
        selectedDays.push(new FormControl(day));
      });
    } else {
      selectedQuadrantLine.days.forEach(day => {
        selectedDays.push(new FormControl(day));
      });
    }

   if (selectedQuadrantLine.hours.length > 0 ) {
     this.hours = selectedQuadrantLine.hours.map((item) => {
       if("pivot" in item){
         return {
           hour: item.hour,
           capacity: item.pivot.capacity
         }
       }
       return {
         hour: item.hour,
         capacity: item.capacity
       }
     });
   } else {
     this.generateHourList();
   }
  }

  generateHourList(section?:QuadrantSection): void {
    this.hours = generateHourList();
  }

  addCapacity(hour: CreateQuadrantHour) {
    hour.capacity += 1;
    this.ofertadas += 1;
  }

  reduceCapacity(hour: CreateQuadrantHour){
    if (hour.capacity > 0) {
      hour.capacity -= 1;
      this.ofertadas -= 1;
    }
  }


  exportHours(type: 'json'|'csv'|'text'){
    if (type === 'csv') {
      exportHoursToCsv(this.hours);
    } else if (type === 'json') {
      exportHoursToJson(this.hours);
    } else if (type === 'text') {
      exportHoursToTxt(this.hours);
    }
  }

  importHours(data: {type: 'json'|'csv'|'text', file: any}){
    if (data.type === 'csv') {
      const file = data.file.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            this.hours = importHoursFromCsv(e, this.toastr);
            this.calculateHours();
          } catch (error) {
            console.error('Error parsing CSV', error);
          }
        };
        reader.readAsText(file);
      }
    } else if (data.type === 'json') {
      const file = data.file.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            this.hours = importHourFromJson(e, this.toastr);
            this.calculateHours();
          } catch (error) {
            console.error('Error parsing JSON', error);
          }
        };
        reader.readAsText(file);
      }
    } else if (data.type === 'text') {
      const file = data.file.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            this.hours = importHoursFromTxt(e, this.toastr);
            this.calculateHours();
          } catch (error) {
            console.error('Error parsing TEXT', error);
          }
        };
        reader.readAsText(file);
      }
    }
  }


  isSelectedDay(dayKey: number): boolean {
    const selectedDays: FormArray = this.sectionForm.get('info') as FormArray;
    return selectedDays.value.includes(dayKey);
  }

  onCheckboxChange(e: any) {
    const selectedDays: FormArray = this.sectionForm.get('info') as FormArray;
    if (e.target.checked) {
      selectedDays.push(new FormControl(+e.target.value));
    } else {
      const index = selectedDays.controls.findIndex(x => x.value === +e.target.value);
      selectedDays.removeAt(index);
    }
  }

  onSaveWorkDaysClick(){
    const days = [1,2,3,4,5];
    const selectedItems: FormArray = this.sectionForm.get('info') as FormArray;
    selectedItems.clear();
    days.forEach((day) => {
      this.onCheckboxChange({target: {checked: true, value: day}})
    });

    this.sectionForm.patchValue({
      id: null,
      name: 'Laborables',
      info: days,
      color: '#02C65C'
    })

    // console.log(this.sectionForm.value);
/*
    this.onFormSubmit.emit({
      id: null,
      name: 'Laborables',
      info: days,
      color: '#02C65C'
    })*/
  }

  onSaveSaturdaysClick(){
    const days = [6];
    const selectedItems: FormArray = this.sectionForm.get('info') as FormArray;
    selectedItems.clear();
    days.forEach((day) => {
      this.onCheckboxChange({target: {checked: true, value: day}})
    });
    this.sectionForm.patchValue({
      id: null,
      name: 'Sábados',
      info: days,
      color: '#F2EAB5'
    })
/*    this.onFormSubmit.emit({
      id: null,
      name: 'Sábados',
      info: days,
      color: '#F2EAB5'
    })*/
  }

  addSection() {
    if (this.sectionForm.valid) {
      this.onFormSubmit.emit(this.sectionForm.value)
    } else {
      this.sectionForm.markAllAsTouched();
    }
  }

  addDate(date: string) {
    const index = this.selectedDays.controls.findIndex(control => control.value === date);
    if (index !== -1) {
      this.selectedDays.removeAt(index);
    } else {
      this.selectedDays.push(new FormControl(date));
    }
    this.onUpdateDates.emit(this.getDates());
  }

  refreshDate(){
    this.selectedDays.reset();
  }

  setHours(hours: AvailableHour[], date: string){
    if (!this.selectedQuadrantLine){
      this.sectionForm.patchValue({
        name: date,
        color: this.getRandomColorHex()
      })
    }
    this.hours = hours.map((item) => {
      return {
        hour: item.hour,
        capacity: item.available,
        books: item.book_count ?? 0
      }
    });
    this.calculateHours();
  }

  getRandomColorHex() {
    const color = '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
    return color;
  }

  getDates() {
    let days: string[] = [];
    this.selectedDays.controls.forEach(day => {
      if (typeof day.value === 'string') {
        days.push(day.value);
      }
    })
    return days;
  }

  get selectedDays(): FormArray {
    return this.sectionForm.get('info') as FormArray;
  }

  cancel() {
    this.resetAll();
    this.onCancel.emit();
  }
  resetAll(){
    this.selectedDays.clear();
    this.generateHourList();
    this.onUpdateDates.emit([]);
    this.ofertadas = 0;
    // this.cancelManualSelectedDays();
    this.sectionForm.reset();
  }
}
