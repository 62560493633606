<div class="flex justify-between mb-2" *ngIf="showImportExport">
  <div>
    <app-import (onImportClick)="importHours($event)"></app-import>
  </div>
  <div *ngIf="selectedQuadrantLine || ofertadas > 0">
    <div class="flex gap-2 items-center">
      <p class="text-darkGray">
        <span class="text-lightGray">{{ ofertadas }}</span> Ofertadas
      </p>
    </div>
  </div>
  <div>
    <app-export (onExportClick)="exportHours($event)"></app-export>
  </div>
</div>
<div class="grid grid-cols-6 gap-[3px]">
  <div class="flex justify-between items-center bg-lightGray2" *ngFor="let hour of hours">
    <span class=" text-[14px] text-darkGray text-center mx-auto"><span *ngIf="hour.books != undefined" [class.text-primary]="hour.books != undefined && hour.books > 0" class="text-xs relative inset-0">{{hour.books}}</span> {{hour.hour}}</span>
    <span [class.bg-green2]="hour.capacity" [class.bg-red-200]="hour.capacity <= 0" class="text-[12px] text-darkGray flex items-center flex-col gap-0 p-1 px-2">
          <svg (click)="addCapacity(hour)"  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"  class="h-3 cursor-pointer"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 15l6 -6l6 6" /></svg>
          <span class="leading-none">{{hour.capacity}}</span>
          <svg  (click)="reduceCapacity(hour)" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"  class="h-3 cursor-pointer"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 9l6 6l6 -6" /></svg>
        </span> <!--<input class="w-2" [(ngModel)]="hour.capacity" type="number" />-->
  </div>
</div>

<!-- *ngIf="!editSectionMode && !editSection" -->
<div  class="py-6">
  <div class="flex justify-center gap-2 my-4" *ngIf="!manualSelectDays && !manualSelectDay && !selectedQuadrantLine">
    <button (click)="onSaveWorkDaysClick()" class="text-sm bg-green hover:shadow-lg text-white rounded-2xl p-2 px-4">LABORABLES</button>
    <button (click)="onSaveSaturdaysClick()" class="text-sm bg-bg2 hover:shadow-lg text-darkGray rounded-2xl p-2 px-4">SÁBADOS</button>
  </div>

  <button  (click)="manualSelectDays = true; onManualSelectDays.emit(true)" *ngIf="!manualSelectDays && !manualSelectDay && !selectedQuadrantLine && onEditMode" class="w-full" appOutlineButton>
    <span>Selección manual</span>
  </button>

  <button  (click)="manualSelectDay = true; onManualSelectDay.emit(true)" *ngIf="!manualSelectDay && !manualSelectDays && !selectedQuadrantLine && onEditMode" class="w-full mt-2" appOutlineButton>
    <span>Ajuste día</span>
  </button>

  <button (click)="manualSelectDays = false; manualSelectDay = false; onManualSelectDays.emit(false); onManualSelectDay.emit(false); cancel()" *ngIf="(manualSelectDays || manualSelectDay) && !selectedQuadrantLine" class="w-full" appOutlineButton>
    <span>Cancelar</span>
  </button>


  <form  [formGroup]="sectionForm" (ngSubmit)="addSection()" class="mt-4">
    <div class="mb-4">
      <div class="flex flex-col mt-2">
        <div class="flex flex-wrap gap-4 justify-center">
          <label *ngIf="!manualSelectDays && !manualSelectDay"  class="text-xs flex items-center gap-1" >
            <ng-container *ngFor="let day of weekDays">
              <input class="rounded-full" type="checkbox" [value]="day.key" (change)="onCheckboxChange($event)" [checked]="isSelectedDay(day.key)" />
              {{ day.name }}
            </ng-container>
          </label>
          <div *ngIf="manualSelectDays">
            <p *ngFor="let control of selectedDays.controls">
              {{ control.value }}
            </p>
          </div>
        </div>
        <span *ngIf="sectionForm.get('info')?.touched && sectionForm.get('info')?.invalid" appFormError>
             <span  *ngIf="!manualSelectDays"> Debes de seleccionar almenos un día</span>
             <span  *ngIf="manualSelectDays"> Debes de seleccionar almenos una fecha</span>
        </span>
      </div>
      <div class="flex flex-col mt-4">
        <input appFormInput  type="text" formControlName="name" placeholder="Nombre línea cuadrante" />
        <span *ngIf="sectionForm.get('name')?.touched && sectionForm.get('name')?.invalid" appFormError>
               El nombre es requerido
        </span>
      </div>

    </div>
    <div class="flex flex-row items-start gap-x-4">
      <div class="flex flex-col">
        <input class="h-10 cursor-pointer hover:shadow-lg duration-300" type="color" formControlName="color">
        <span *ngIf="sectionForm.get('color')?.touched && sectionForm.get('color')?.invalid" appFormError>
                Debes de seleccionar un color
              </span>
      </div>
      <button type="submit" class="w-full rounded-lg" appNextButton>
        <span *ngIf="!selectedQuadrantLine">Guardar</span>
        <span *ngIf="selectedQuadrantLine">Guardar cambios</span>
      </button>
    </div>
  </form>



</div>
