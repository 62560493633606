import { Injectable } from '@angular/core';
import {map, Observable} from "rxjs";
import {Response} from "../../../models/Response";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Station} from "../../../models/Station";
import {State} from "../../../models/State";
import {StationForm} from "../../../models/StationForm/StationForm";
import {Quadrant} from "../../../models/Quadrant";
import {QuadrantInfo} from "../../../models/QuadrantInfo";
import {QuadrantLine} from "../../../models/QuadrantLine";
import {Step2Quadrants} from "../../../models/StationForm/Step2Quadrants";
import {SortColumn} from "../../../models/Table/SortColumn";
import {ActiveFilter} from "../../../models/Table/ActiveFilter";
import {completeHttpFiltersAndSorts} from "../../../const/functions";
import {AvailableHoursInfo} from "../../../models/AvailableHours";



@Injectable({
  providedIn: 'root'
})
export class StationService {

  constructor(private _http: HttpClient) { }

  list(): Observable<Response<Station[]>>{
    return this._http.get<Response<Station[]>>(`${environment.API_URL}/stations/list`);
  }

  getById(stationId: number|string): Observable<Response<Station>>{
    return this._http.get<Response<Station>>(`${environment.API_URL}/stations/get/${stationId}`)
  }

  listByState(serviceId: number|null = null, vehicleId: number|null = null): Observable<Response<State[]>>{
    let url = `${environment.API_URL}/stations/list/state`;

    if (serviceId && vehicleId) {
      url += `/${serviceId}/${vehicleId}`;
    }

    return this._http.get<Response<State[]>>(url);
  }

  listAvailability(serviceId: number, vehicleId:number, fuelId: number, stateId: number): Observable<Response<Station[]>>{
    return this._http.get<Response<Station[]>>(`${environment.API_URL}/stations/list/stations/${serviceId}/${vehicleId}/${fuelId}/${stateId}`);
  }

  create(stationForm: StationForm): Observable<Response<boolean>> {
    return this._http.post<Response<boolean>>(`${environment.API_URL}/stations/create`, stationForm);
  }

  update(stationForm: StationForm, stationId: number): Observable<Response<Station>> {
    return this._http.patch<Response<Station>>(`${environment.API_URL}/stations/update/${stationId}`, {step1: {...stationForm }});
  }

  addQuadrants(stationForm:Step2Quadrants[],stationId: number): Observable<Response<Quadrant[]>>{
    return this._http.patch<Response<Quadrant[]>>(`${environment.API_URL}/stations/addQuadrants/${stationId}`, {quadrants: stationForm});
  }

  updateShow(data:any, stationId: number): Observable<Response<Station>> {
    return this._http.patch<Response<Station>>(`${environment.API_URL}/stations/update-show/${stationId}`,data);
  }

  quadrants(stationId: number): Observable<Response<Quadrant[]>> {
    return this._http.get<Response<Quadrant[]>>(`${environment.API_URL}/stations/quadrants/${stationId}`);
  }

  quadrantInfo(stationId: number, quadrantId: number, month: number, year: number): Observable<Response<QuadrantInfo>> {
    return this._http.get<Response<QuadrantInfo>>(`${environment.API_URL}/stations/quadrants/info/${stationId}/${quadrantId}/${month}/${year}`);
  }

  quadrantInfoDate(stationId: number, quadrantId: number, date: string): Observable<Response<AvailableHoursInfo>> {
    return this._http.get<Response<AvailableHoursInfo>>(`${environment.API_URL}/stations/quadrants/info-day/${stationId}/${quadrantId}/${date}`);
  }

  addLineToQuadrant(quadrantId: number, body: any){
    return this._http.post<Response<QuadrantLine>>(`${environment.API_URL}/stations/quadrant/${quadrantId}/line`, body);
  }

  editQuadrantLine(quadrantLineId: number, body: any){
    return this._http.put<Response<QuadrantLine>>(`${environment.API_URL}/stations/quadrant/line/${quadrantLineId}`, body);
  }
  deleteQuadrantLine(quadrantLineId: number){
    return this._http.delete<Response<QuadrantLine>>(`${environment.API_URL}/stations/quadrant/line/${quadrantLineId}`);
  }

  stationsBlocked(stationId: string|number): Observable<Response<QuadrantLine[]>>{
    return this._http.get<Response<QuadrantLine[]>>(`${environment.API_URL}/states/quadrant/station/${stationId}`)
  }

  addQuadrantReduction(stationId: number|string, body: any): Observable<Response<QuadrantLine>>{
    return this._http.post<Response<QuadrantLine>>(`${environment.API_URL}/states/quadrant/station/${stationId}`, body)
  }
  addQuadrantReductionTOQuadrant(quadrantId: number|string, body: any): Observable<Response<QuadrantLine>>{
    return this._http.post<Response<QuadrantLine>>(`${environment.API_URL}/states/quadrant/quadrant/${quadrantId}`, body)
  }

  generalHomeInfo(): Observable<{ id: number, name: string, reservations_true_count: number, reservations_false_count: number, total: number }[]>{
    return this._http.get<{ id: number, name: string, reservations_true_count: number, reservations_false_count: number, total: number }[]>(`${environment.API_URL}/general/yearInfo`)
  }

  smsIfo(sorts: SortColumn[] = [], filters: ActiveFilter[] = []): Observable<{ id: number, name: string, sms_count: number }[]>{
    let params = completeHttpFiltersAndSorts(sorts, filters);
    // console.log(params);
    return this._http.get<{ id: number, name: string, sms_count: number}[]>(`${environment.API_URL}/general/smsInfo`,{ params })
  }

  updateQuadrantMessage(quadrantId: number|string, body: any){
    return this._http.post<Response<null>>(`${environment.API_URL}/stations/quadrant/${quadrantId}/message`, {message: body})
  }
  updateQuadrantOpen(quadrantId: number|string){
    return this._http.post<Response<null>>(`${environment.API_URL}/stations/quadrant/${quadrantId}/open`, null)
  }

  updateQuadrantAddMessage(quadrantId: number|string){
    return this._http.post<Response<null>>(`${environment.API_URL}/stations/quadrant/${quadrantId}/add_message`, null)
  }
}
