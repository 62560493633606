import {Component, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {FormErrorDirective} from "../../../../../../../../shared/directives/forms/form-error.directive";
import {LoaderComponent} from "../../../../../../../../shared/components/loader/loader.component";
import {NextOutlinedButtonDirective} from "../../../../../../../../shared/directives/buttons/next-outlined.directive";
import {NgForOf, NgIf} from "@angular/common";
import {OldReservation, Reservation} from "../../../../../../../../models/Reservation";
import {ReservationForm, Step1} from "../../../../../../../../models/Front/ReservationForm";
import {updateStep1} from "../../../../../../../../store/reservationForm/stepper.actions";
import {FrontService} from "../../../../../../../../core/services/front/front.service";
import {BookFormService} from "../../../../../../../../core/services/book-form.service";
import {
  ExistingReservationCardComponent
} from "../../../../../../../front/components/existing-reservation-card/existing-reservation-card.component";
import {NextButtonDirective} from "../../../../../../../../shared/directives/buttons/next.directive";
import {OldReservationComponent} from "../../../../../../../front/components/old-reservation/old-reservation.component";
import {UserService} from "../../../../../../../../core/services/users/user.service";
import {User} from "../../../../../../../../models/User";
import {UserV2} from "../../../../../../../../models/UserV2";
import {
  FormInputBlueNoFullDirective
} from "../../../../../../../../shared/directives/forms/form-input-blue-no-full.directive";
import {Vehicle} from "../../../../../../../../models/Vehicle";
import {NgxSpinner, NgxSpinnerService} from "ngx-spinner";
import {Subject, takeUntil} from "rxjs";
import { FloatLabelModule } from 'primeng/floatlabel';
import { MultiSelectModule } from 'primeng/multiselect';
import { MultiSelect } from 'primeng/multiselect';

@Component({
  selector: 'app-step1',
  standalone: true,
  imports: [
    FormErrorDirective,
    FormsModule,
    LoaderComponent,
    NextOutlinedButtonDirective,
    NgIf,
    ReactiveFormsModule,
    ExistingReservationCardComponent,
    NextButtonDirective,
    OldReservationComponent,
    FormInputBlueNoFullDirective,
    NgForOf,
    FloatLabelModule,
    MultiSelectModule,
  ],
  templateUrl: './step1.component.html',
  styleUrl: './step1.component.css'
})
export class Step1Component implements OnDestroy{
  @ViewChild('multiSelect') multiSelect!: MultiSelect;
  loading: boolean = false;
  error: string|null = null;

  existingReservation?: Reservation;
  oldReservation?: OldReservation;

  editReservation:boolean = false;

  stolen = false;

  step1Form: FormGroup;

  professional: boolean = false;

  currentStep: number = 1;

  users: UserV2[] = [];
  selectedUser?:UserV2 ;

  private destroy$ = new Subject<void>();

  ngOnDestroy() {
    this.destroy$.next(); // Emite un valor para completar las suscripciones
    this.destroy$.complete(); // Finaliza el Subject
  }

  constructor(private frontService: FrontService, private bookFormService: BookFormService, private userService: UserService,
              private spinner: NgxSpinnerService) {
    this.step1Form = new FormGroup({
      registration_number: new FormControl(null, [Validators.required])
    });
    this.bookFormService.reservationForm$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
      next: (form: ReservationForm) => {
        this.currentStep = form.step;
        if (!this.professional) {
          this.selectedUser = undefined;
        }
        if (this.professional != form.professional) {
          this.professional = form.professional;
          if(this.professional) {
            this.loadProfessionals();
          }
        }
      }
    })

    this.bookFormService.reservationResetForm$.subscribe({
      next: (form: undefined) => {
        this.step1Form.markAsUntouched();
        this.stolen = false;
        this.step1Form.patchValue({
          registration_number: ''
        })
        if (this.professional) {
          this.selectedUser = undefined;
        }
      }
    })


  }

  loadProfessionals(){
    this.loading = true;
    this.userService.listProfessionals().subscribe({
      next: (response) => {
        if(response.data) {
          this.users = response.data;
          this.loading = false;
        }
      },
      error: err => {
        this.loading = false;
      }
    })
  }

  onAddRegistrationNumber() {
    this.error = null;
    if(this.step1Form.valid){
      let data:any = {
        registration_number: this.step1Form.get('registration_number')?.value,
        professional: this.selectedUser,
      }
      this.reset();
      //this.loading=true;
      this.spinner.show();
      this.frontService.checkReservation(data, this.selectedUser?.id).subscribe(
        {
          next: (response) => {

            //this.resetWithoutRegistration()

            if (response.data) {
              // Verificar si es una OldReservation por la existencia de 'expiration' y ausencia de 'id'
              if ('expiration' in response.data && !('id' in response.data)) {
                this.oldReservation = response.data as OldReservation;
              }

              // Verificar si es una Reservation por la existencia de las propiedades clave como 'id'
              if ('id' in response.data) {
                this.existingReservation = response.data as Reservation;
              }

              if ('stolen' in response.data) {
                // @ts-ignore
                this.stolen = response.data.stolen;
              }

              if ('continue' in response.data) {
                // @ts-ignore
                this.next();
              }

            }
            this.spinner.hide();
            //this.loading = false;
          },
          error: error => {
            this.error = error.error.message;
            this.spinner.hide();
            //this.loading = false;
          }
        }
      );
    }
  }

  next(){
    const step1: Step1 = this.step1Form.value;
    this.bookFormService.setStep1(step1);
    this.existingReservation=undefined;
    this.oldReservation=undefined;
    if(this.professional && this.selectedUser) {
      this.bookFormService.setProfessionalId(this.selectedUser.id);
    }
  }

  public returnRegistration(){
    this.existingReservation=undefined;
    this.oldReservation=undefined;
  }

  clearOldReservation(key:string){
    // this.loadServices();
    this.oldReservation=undefined;
  }

  updateRegistrationNumber(){
    const step1: Step1 = this.step1Form.value;
    if (step1.registration_number) {
      let data:any = {
        ...this.step1Form.value,
        professional: this.selectedUser,
      }
      this.spinner.show();
      this.reset();
      this.frontService.checkReservation(data, this.selectedUser?.id).subscribe(
        {
          next: (response) => {

            //this.resetWithoutRegistration()

            if (response.data) {
              // Verificar si es una OldReservation por la existencia de 'expiration' y ausencia de 'id'
              if ('expiration' in response.data && !('id' in response.data)) {
                this.oldReservation = response.data as OldReservation;
              }

              // Verificar si es una Reservation por la existencia de las propiedades clave como 'id'
              if ('id' in response.data) {
                this.existingReservation = response.data as Reservation;
              }

              if ('stolen' in response.data) {
                // @ts-ignore
                this.stolen = response.data.stolen;
              }

              if ('continue' in response.data) {
                // @ts-ignore
                this.bookFormService.updateRegistrationNumber(step1.registration_number);
              }

            }

            this.spinner.hide();
          },
          error: error => {
            this.error = error.error.message;
            this.spinner.hide();
          }
        }
      );

    }
  }

  reset(){
    this.bookFormService.reset(this.step1Form.value, this.professional);
    if (this.professional && this.selectedUser) {
      this.bookFormService.updateStep5({
        name: this.selectedUser.name,
        phone_number: this.selectedUser.phone,
        email: this.selectedUser.email
      })
      this.bookFormService.setProfessionalId(this.selectedUser.id);
    }
  }

  onSelectUser(event: any){
    this.selectedUser = event.itemValue;
    
    if (this.multiSelect && this.selectedUser) {
      this.multiSelect.hide();
    }

    if (event.value.length === 0) {
      this.selectedUser = undefined;
    }

    if (this.selectedUser) {
      this.step1Form.patchValue({
        'registration_number': this.selectedUser.name
      })
      // this.bookFormService.setStep1(this.step1Form.value);
      this.bookFormService.updateStep5({
        name: this.selectedUser.name,
        phone_number: this.selectedUser.phone,
        email: this.selectedUser.email
      })
      // this.next();

      this.updateRegistrationNumber();
    }
  }

  clearSelectedUser() {
    this.selectedUser = undefined;
    this.multiSelect.hide();
  }
}
