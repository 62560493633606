<div>
  <div *ngIf="stationByParam" class="w-full mb-3">
    <button (click)="goToStation()" appNextGreenButton>VOLVER A LA ESTACIÓN</button>
  </div>
  <div class="flex gap-4 items-center justify-start w-full">
    <p>Selecciona cuadrante: </p>
    <select appFormInputNoFull (change)="loadQuadrants(true)" [(ngModel)]="selectStation" name="station" id="station">
      <option disabled [value]="null">Selecciona una estación</option>
      <option *ngFor="let station of stations" [value]="station.id">{{station.name}}</option>
    </select>
    <select appFormInputNoFull (change)="loadQuadrantInfo()"  name="quadrant" id="quadrant" *ngIf="selectStation" [(ngModel)]="selectQuadrant">
      <option disabled [value]="null">Selecciona un cuadrante</option>
      <option *ngFor="let quadrant of quadrants" [value]="quadrant.id">{{quadrant.name}}</option>
    </select>
    <app-loader *ngIf="loadingFilters"></app-loader>
  </div>

  <div class="w-full max-w-3xl mt-4" *ngIf="quadrantLink">
    <app-quadrant-edit-link [quadrant]="quadrantLink"></app-quadrant-edit-link>
  </div>

  <div class="w-full grid grid-cols-12 gap-4 mt-8 min-h-full" *ngIf="quadrantInfo">
    <div class="border-r-2 border-lightGray pr-2 col-span-2">
      <div class="flex flex-col gap-3">
        <div class="flex gap-2 items-center">
          <span  class="w-4 h-4 bg-lightGray rounded-full"></span>
          <p class="text-darkGray">
            <span class="text-lightGray">{{ quadrantInfo.info.total }}</span> Ofertadas
          </p>
        </div>

        <div class="flex gap-2 items-center">
          <span  class="w-4 h-4 bg-primary rounded-full"></span>
          <p class="text-darkGray">
            <span class="text-primary">{{ quadrantInfo.info.booked }}</span> Reservadas
          </p>
        </div>

        <div class="flex gap-2 items-center">
          <span  class="w-4 h-4 bg-green rounded-full"></span>
          <p class="text-darkGray">
            <span class="text-green" *ngIf="quadrantInfo.info.free >= 0 ">{{ quadrantInfo.info.free }} Libres</span>
            <span class="text-red-500" *ngIf="quadrantInfo.info.free < 0 ">{{ Math.abs(quadrantInfo.info.free) }} Sobrepasadas</span>
          </p>
        </div>
      </div>
      <div class="flex flex-col gap-3 mt-12">
        <div *ngFor="let section of quadrantInfo.lines" class="flex items-center justify-between gap-2">
          <div>
            <span [style.background-color]="section.color"  class="h-4 w-4 rounded-full"></span>
            <span class="text-darkGray flex justify-start items-start gap-1">{{ section.name }} <span class="text-[7px] text-primary font-bold" *ngIf="section.is_day">DÍA</span> </span>
          </div>
          <div class="flex items-center justify-end gap-2">
            <button (click)="selectSection(section)" *ngIf="!selectedSection || selectedSection.id != section.id" [style.background]="section.color" class="text-white rounded-full p-1 hover:shadow-xl duration-300 hover:scale-105">
              <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5 h-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>
            </button>
            <button (click)="cancelEdit()" *ngIf="selectedSection && selectedSection.id === section.id" [style.background]="section.color" class="text-white rounded-full p-1 opacity-70 hover:shadow-xl duration-300 hover:scale-105">
              <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5 h-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M10.507 10.498l-1.507 1.502v3h3l1.493 -1.498m2 -2.01l4.89 -4.907a2.1 2.1 0 0 0 -2.97 -2.97l-4.913 4.896" /><path d="M16 5l3 3" /><path d="M3 3l18 18" /></svg>
            </button>
            <button (click)="deleteSection(section)" class="bg-red-500 hover:bg-red-600 text-white rounded-full p-1 hover:shadow-xl duration-300 hover:scale-105">
              <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="currentColor"  class="w-5 h-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M20 6a1 1 0 0 1 .117 1.993l-.117 .007h-.081l-.919 11a3 3 0 0 1 -2.824 2.995l-.176 .005h-8c-1.598 0 -2.904 -1.249 -2.992 -2.75l-.005 -.167l-.923 -11.083h-.08a1 1 0 0 1 -.117 -1.993l.117 -.007h16zm-9.489 5.14a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" /><path d="M14 2a2 2 0 0 1 2 2a1 1 0 0 1 -1.993 .117l-.007 -.117h-4l-.007 .117a1 1 0 0 1 -1.993 -.117a2 2 0 0 1 1.85 -1.995l.15 -.005h4z" /></svg>
            </button>
          </div>
        </div>
        <div class="mt-4">
          <div class="flex gap-2 items-center">
            <p class="text-darkGray text-xl">BLOQUEOS</p>
            <div class="w-6 h-6 rounded-full bg-bg2"></div>
          </div>
          <div  *ngFor="let section of quadrantLinesBlocked">
            <app-blocked-dates *ngIf="section.quadrant_id == selectQuadrant" [quadrantLinesBlocked]="section"></app-blocked-dates>
            <div *ngIf="section.quadrant_id != selectQuadrant" class="flex justify-between mt-2 mb-2">
              <div>
                <p class="text-darkGray">{{section.name}}</p>
                <p class="text-xs text-primary" *ngIf="!section.state_id && !section.station_id">Nacional</p>
                <p class="text-xs text-primary" *ngIf="!section.state_id && section.station_id">Estación</p>
                <p class="text-xs text-primary" *ngIf="section.state_id">Regional</p>
                <div>
                  <span class="text-lightGray text-sm" *ngFor="let h of section.blocked">de {{h.init }} a {{h.finish}}</span>
                </div>
              </div>
              <div>
                <button class="bg-primary hover:shadow text-white rounded-full p-1" (click)="show.toggle($event)">
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>
                  <p-overlayPanel #show>
                    <p *ngFor="let date of section.info">{{ date | date:'dd/MM/yy' }}</p>
                  </p-overlayPanel>
                </button>
              </div>
              <!--<p *ngFor="let day of section.info" >
                {{day}}
              </p>-->
            </div>
          </div>

        </div>
        <div class="mt-2">
          <app-community-blocked-form #blockedForm (onSubmitSend)="onCreate($event)"></app-community-blocked-form>
        </div>
      </div>
      <!--<div *ngIf="selectedSection">
        <button (click)="cancelEdit()" class="w-full bg-red-500 text-white p-1 mt-2">CANCELAR</button>
      </div>-->
      <!--<button *ngIf="!manualSelectedMode" (click)="manualSelectedMode = !manualSelectedMode" class="w-full bg-green text-white p-1 mt-2">AÑADIR</button>
      <div *ngIf="manualSelectedMode" class="">
        <p *ngFor="let s of selectedDays">{{s}}</p>
      </div>
      <button *ngIf="manualSelectedMode" (click)="manualSelectedMode = !manualSelectedMode" class="w-full bg-red-500 text-white p-1 mt-2">CANCELAR</button>-->
    </div>
    <div class="col-span-6">

      <app-month-calendar
        [year]="year"
        [month]="month"
        [loading]="loadingFilters"
        [changeMonth]="true"
        (nextMonth)="nextMonth()"
        (prevMonth)="prevMonth()"
        [quadrantMode]="true"
        [quadrantInfo]="quadrantInfo"
        [selectDaysMode]="manualSelectedMode"
        [selectDayMode]="manualSelectedOneMode"
        (onSelectDayChange)="onSelectDay($event)"
        [currentSelection]="selectedDates"
      >
        <!--<div afterMonth *ngIf="!loadingFilters">
          <div class="flex gap-2 items-center">
            <p class="text-lightGray text-lg border-r border-lightGray pr-2 font-bold">{{ quadrantInfo.info.total }}</p>
            <p class="text-primary text-lg border-r border-lightGray pr-2 font-bold">{{ quadrantInfo.info.booked }}</p>
            <p class="text-green text-lg font-bold">{{ quadrantInfo.info.free }}</p>
          </div>
        </div>-->
      </app-month-calendar>
    </div>
    <div class="col-span-4">
      <app-hours-form #hoursForm
        [onEditMode]="true"
        (onManualSelectDays)="manualSelectedMode = $event"
        (onManualSelectDay)="manualSelectedOneMode = $event"
        (onCancel)="selectedSection = undefined"
         (onUpdateDates)="onUpdateDates($event)"
           (onFormSubmit)="onFormSubmit($event)"
         [manualSelectDays]="manualSelectedMode"
         [manualSelectDay]="manualSelectedOneMode"
        [selectedQuadrantLine]="selectedSection"></app-hours-form>
    </div>
  </div>


</div>

<div *ngIf="quadrantInfo && quadrantInfo.quadrant.type ==='message'" class="grid grid-cols-2 mt-4">
  <div class="flex flex-col justify-end">
    <textarea appFormInput rows="5" [(ngModel)]="quadrantInfo.quadrant.message"></textarea>
    <button (click)="saveNewMessage()" class="mt-2" appNextButton>GUARDAR</button>
    <button (click)="quitMessage()" class="bg-red-500 text-white hover:bg-red-600 mt-2" appNextButton>ABRIR</button>
  </div>
</div>

<div *ngIf="quadrantInfo && quadrantInfo.quadrant.type !=='message'" class="grid grid-cols-2 mt-4">
  <div class="flex flex-col justify-end">
    <button (click)="addTypeMessage()" class="bg-amber-500 text-white hover:bg-amber-600 mt-2" appNextButton>CAMBIAR A TIPO MENSAJE</button>
  </div>
</div>


<div *ngIf="quadrantInfo && quadrantInfo.quadrant && quadrantInfo.quadrant.quadrant_logs && user && user.roles.includes('super_admin')" class="max-w-2xl mt-12">

  <ol class="relative border-s border-gray-200 dark:border-gray-700">
    <li *ngFor="let log of quadrantInfo.quadrant.quadrant_logs; let i = index" class="mb-10 ms-6">
        <span class="absolute flex items-center justify-center w-6 h-6 bg-primary rounded-full -start-3 ring-8 ring-white">
            <svg  xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-4 text-white"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" /><path d="M16 3l0 4" /><path d="M8 3l0 4" /><path d="M4 11l16 0" /><path d="M8 15h2v2h-2z" /></svg>
        </span>
      <div class="p-4 bg-white border border-gray-200 rounded-lg shadow-sm">
        <div class="items-center justify-between mb-3 sm:flex">
          <time class="mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0">{{ log.created_at | date:'dd/M/Y HH:mm' }}</time>
          <div class="text-sm font-normal text-gray-500 lex ">{{log.user_email}} <span *ngIf="i == 0" class="p-1 bg-primary text-white font-bold text-xs rounded-md">actual</span></div>
        </div>
        <div class="p-3 text-xs italic font-normal text-gray-500 border border-gray-200 rounded-lg bg-gray-50 ">
          {{log.info}}
        </div>
      </div>
    </li>

  </ol>


</div>
