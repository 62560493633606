import { Component } from '@angular/core';
import {FrontService} from "../../../../../core/services/front/front.service";
import {AuthStateService} from "../../../../../core/services/auth/auth-state.service";
import {Service} from "../../../../../models/Service";
import {Vehicle} from "../../../../../models/Vehicle";
import {Fuel} from "../../../../../models/Fuel";
import {BookFormService} from "../../../../../core/services/book-form.service";
import {CardSelectorComponent} from "../../utils/card-selector/card-selector.component";
import {CardShadowComponent} from "../../utils/card-shadow/card-shadow.component";
import {FrontBackDirective} from "../../../../../shared/directives/buttons/front.back.directive";
import {NgForOf, NgIf} from "@angular/common";
import {updateStep} from "../../../../../store/reservationForm/stepper.actions";

@Component({
  selector: 'app-step2',
  standalone: true,
  imports: [
    CardSelectorComponent,
    CardShadowComponent,
    FrontBackDirective,
    NgForOf,
    NgIf
  ],
  templateUrl: './step2.component.html',
  styleUrl: './step2.component.css'
})
export class Step2Component {

  loading: boolean = false;

  isProfessional = false;

  serviceSelected?:Service;
  vehicleSelected?:Vehicle;
  fuelSelected?:Fuel;

  services?:Service[];
  vehicles?:Vehicle[];
  fuels?:Fuel[];

  substep: number = 1;

  constructor(private frontService:FrontService, private authStateService: AuthStateService, private bookFormService: BookFormService,) {
    this.authStateService.currentUser.subscribe({
      next: data => {
        if (data && data.roles.includes('professional')) {
          this.isProfessional = true;
        }
      }
    })

    this.bookFormService.reservationForm$.subscribe({
      next: data => {
        if (data.steps.step2) {
          this.serviceSelected = data.steps.step2.service;
          this.vehicleSelected = data.steps.step2.vehicle;
          this.fuelSelected = data.steps.step2.fuel;
          // this.substep = data.steps.step2.subStep ?? 1;
        }
      }
    })

    this.loadServices();
  }

  public nextSubStep(step: number) {
    this.substep = step;
    // this.update();
  }

  loadServices(){
    this.loading = true;
    this.frontService.listServices().subscribe(
      {
        next: (response) => {
          if(response.data){
            this.services=response.data;
          }
          this.loading = false;
        },
        error: err => {
          this.loading = false;
        }
      }
    );
  }

  public selectService(service: Service) {
    this.serviceSelected = service;
    this.loadVehicles(service);
    this.nextSubStep(2);
  }

  loadVehicles(service: Service) {
    this.loading = true;
    this.frontService.listVehicles(service.id).subscribe(
      {
        next: (response) => {
          if(response.data){
            this.vehicles=response.data;
          }
          this.loading = false;
        },
        error: err => {
          // console.log(err);
          this.loading = false;
        }
      }
    );
  }

  public selectVehicle(vehicle:Vehicle) {
    this.vehicleSelected = vehicle;
    if(this.serviceSelected){
      this.loadFuels(vehicle, this.serviceSelected);
      if (this.vehicleSelected.need_fuel) {
        this.nextSubStep(3);
      }
    }
  }

  loadFuels(vehicle:Vehicle, service: Service){
    this.loading = true;
    this.frontService.listFuels(service.id,vehicle.id).subscribe(
      {
        next: (response) => {
          if(response.data){
            this.fuels=response.data;
            if (this.vehicleSelected && !this.vehicleSelected.need_fuel) {
              let fuel =  this.fuels ? this.fuels.find(fuel => fuel.slug === "sin-combustible") : null;
              if (fuel) {
                this.bookFormService.setStep2({
                  subStep: this.substep,
                  service: this.serviceSelected,
                  vehicle: this.vehicleSelected,
                  fuel: fuel
                })
              }
            }
          }
          this.loading = false;
        },
        error: err => {
          this.loading = false;
        }
      }
    );
  }

  public selectFuel(fuel: Fuel) {
    this.fuelSelected = fuel;

    this.bookFormService.setStep2({
      subStep: this.substep,
      service: this.serviceSelected,
      vehicle: this.vehicleSelected,
      fuel: this.fuelSelected
    })
    // this.update();
  }

  public backSubStep(){
    if (this.substep > 1) {
      this.substep--;
    } else {
     this.bookFormService.setCurrentStep(1);
    }
  }

}
