<div class="w-full">
  <div *ngIf="substep === 1" class="w-full animate-fade-in-down">
    <p class="text-2xl mb-10 text-darkGray">Elige tu Comunidad</p>
    <app-card-shadow  [showLoading]="loading">
      <app-card-selector *ngFor="let state of states" (onClickCard)="selectState(state)" [selected]="stateSelected?.id == state.id">
        <div class="w-fit flex justify-center items-center px-2 py-1 rounded-xl bg-lightGray2 mr-4"><img class="w-10 h-10 object-contain" [alt]="state.name" [src]="state.logo ? state.logo.original_url : '/assets/img/not-found.png'"></div><span>{{ state.name }}</span>
      </app-card-selector>
      <!--<div *ngFor="let state of states" (click)="selectState(state)" class=" flex items-center border rounded-full px-4 py-2 text-start text-darkGray w-96 h-14 cursor-pointer hover:bg-blue-100 duration-300 {{step3Form.get('community')?.value == 'Castilla la Mancha' ? 'border-blue-600' : 'border-lightGray'}}">

      </div>-->
    </app-card-shadow>
  </div>

  <div *ngIf="substep === 2 " class="animate-fade-in-down">
    <p class="text-2xl mt-6 text-darkGray mb-10">Elige Tu Centro</p>
    <app-card-shadow  [showLoading]="loading">
      <app-card-selector *ngFor="let station of stations" (onClickCard)="selectStation(station)"  [selected]="stationSelected?.id == station.id">
        <div class="flex justify-between w-full items-center">
          <p>{{ station.name }}</p>
          <div class="flex flex-col text-xs text-center min-w-24 items-center" *ngIf="station.state_id == 3 && !station.quadrant_link">
            <p class="text-[10px] text-primary px-4">Primera cita disponible</p>
            <div class="flex flex-row w-full gap-x-1 " *ngIf="station.first_day_available && station.first_hour_available; else noinfo">
              <p class="p-1 bg-lightBlue text-primary rounded-full w-full text-center">{{ station.first_day_available | date:'dd MMM':'':'es' | uppercase }}</p>
              <p class="p-1 bg-lightGreen2 text-primary rounded-full w-full text-center">{{station.first_hour_available.hour}}</p>
            </div>
            <p class="text-[9px] mt-2 border border-yellow text-darkGray px-[5px] py-[2px] rounded-md max-w-22" *ngIf="station.professional">PROFESIONALES</p>
            <ng-template #noinfo>
              <span class="text-[9px] text-darkGray px-4">Sin información</span>
            </ng-template>
          </div>
        </div>
      </app-card-selector>
    </app-card-shadow>
  </div>

  <div class="flex flex-row justify-between mt-4">
    <button (click)="backSubStep()" appFrontBack>
      <span>
        <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M5 12l4 4" /><path d="M5 12l4 -4" /></svg>
      </span>
      <span>Atrás</span>

    </button>
  </div>
</div>


